import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import slugify from 'slugify'

import styles from './faq.module.css'

import AccordionListItem from '../components/accordion-list-item'
import AccordionList from '../components/accordion-list'
import Tabs from '../components/tabs'
import Tab from '../components/tab'
import SEO from '../components/seo'

const FAQCategories = ({ categories, currentIndex, setIndex }) => {
  return (
    <>
      <Tabs className={styles.tabs}>
        {categories.map((c, i) => (
          <Tab
            key={i}
            title={c.name}
            isActive={currentIndex === i}
            headerClass={styles.tabHeader}
            onClick={() => setIndex(i)}
          />
        ))}
      </Tabs>
    </>
  )
}

export const FAQTemplate = ({ page, hash }) => {
  const { title, faqCategories } = page.frontmatter
  const [currentIndex, setCurrentIndex] = useState(0)

  const setIndexRef = useRef()

  setIndexRef.current = i => setCurrentIndex(i)

  return (
    <>
    <SEO 
      title={"The Projector Frequently Asked Questions (FAQs) Answered."}
      description={"All the frequently asked questions (FAQ) about the Projector cinema venue, services, ticketing, giftcards, membership, gifts and general matters answered."}
    />
    <div className="container flex flex-col text-center py-5 lg:py-20">
      <h1 className={styles.title}>{title}</h1>
      <FAQCategories
        categories={faqCategories}
        currentIndex={currentIndex}
        setIndex={setIndexRef.current}
      />
      {faqCategories.map((c, catI) => (
        <AccordionList
          key={catI}
          className={`${styles.accordionList} ${
            catI === currentIndex ? styles.accordionListSelected : ''
          }`}
        >
          {c.questions.map((qn, i) => {
            const qnSlug = slugify(qn.question).toLowerCase()

            return (
              <AccordionListItem
                key={i}
                slug={qnSlug}
                title={qn.question}
                defaultIsExpanded={hash === qnSlug}
              >
                <div dangerouslySetInnerHTML={{ __html: qn.answerHtml }} />
              </AccordionListItem>
            )
          })}
        </AccordionList>
      ))}
    </div>
    </>
  )
}

const FAQPage = ({ data, location }) => {
  return <FAQTemplate hash={location.hash} {...data} />
}

FAQPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default FAQPage

export const pageQuery = graphql`
  query FAQPage {
    page: markdownRemark(fields: { slug: { eq: "/faq/" } }) {
      frontmatter {
        title
        faqCategories {
          name
          icon {
            childImageSharp {
              fluid(maxWidth: 90, maxHeight: 55, quality: 90, fit: CONTAIN) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          questions {
            question
            answerHtml
          }
        }
      }
    }
  }
`
