import React from 'react'
import PropTypes from 'prop-types'

import styles from './tabs.module.css'
import Tab from './tab'
import { classes } from '../util/components'

const Tabs = ({ title, children, className, selectClass }) => {
  const childrenArray = (Array.isArray(children)
    ? children
    : [children]
  ).filter(c => !!c)
  const selectedTab = childrenArray.find(c => c.props.isActive)
  const tabHeaders = childrenArray.map((c, i) => (
    <div
      className={classes(
        styles.tabHeader,
        c.props.isActive && styles.active,
        c.props.headerClass
      )}
      key={i}
      onClick={c.props.onClick}
    >
      {c.props.title}
      {c.props.titleIcon ? c.props.titleIcon : null}
    </div>
  ))

  const onSelectChange = ev => {
    const selected = childrenArray.find(c => c.props.title === ev.target.value)
    if (selected && selected.props.onClick) {
      selected.props.onClick()
    }
  }

  const tabSelect = (
    <select
      className={classes(styles.tabSelect, selectClass)}
      value={selectedTab && selectedTab.props.title}
      onChange={onSelectChange}
    >
      {childrenArray.map((c, i) => (
        <option key={i} value={c.props.title} hidden={c.props.hidden}>
          {c.props.dropdownTitle || c.props.title}
        </option>
      ))}
    </select>
  )

  return (
    <div className={classes(styles.tabs, className)}>
      <div className={styles.tabHeaders}>
        {title ? <span className={styles.title}>{title}</span> : null}
        {tabSelect}
        {tabHeaders}
      </div>
      {children}
    </div>
  )
}

Tabs.propTypes = {
  title: PropTypes.string,
  children: PropTypes.arrayOf(Tab).isRequired,
  className: PropTypes.string,
  selectClass: PropTypes.string,
}

export default Tabs
