import React from 'react'
import PropTypes from 'prop-types'
import { classes } from '../util/components'

import styles from './tab.module.css'

const Tab = ({
  title,
  titleIcon,
  dropdownTitle,
  onClick,
  headerClass,
  omitInactive = true,
  children,
  isActive,
}) => {
  return (
    <div className={classes(styles.tab, isActive && styles.active)}>
      {!isActive && omitInactive ? null : children}
    </div>
  )
}

Tab.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.object,
  dropdownTitle: PropTypes.string,
  onClick: PropTypes.func,
  headerClass: PropTypes.string,
  children: PropTypes.node,
  isActive: PropTypes.bool,
}

export default Tab
