import React, { useState, useRef, useEffect, useCallback } from 'react'
import lowerCase from 'lodash/lowerCase'

import PlusIcon from './plus-icon'
import { classes } from '../util/components'
import styles from './accordion-list-item.module.css'

const AccordionListItem = ({
  slug,
  title,
  children,
  defaultIsExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded)
  const [maxHeight, setMaxHeight] = useState(0)

  const isClickLockedRef = useRef(false)
  const isFocusedRef = useRef(false)
  const contentRef = useRef()

  // Done to prevent the click handler from
  // immediately closing the dropdown on the first click
  const lockdownClick = useCallback(() => {
    isClickLockedRef.current = true
    setTimeout(() => (isClickLockedRef.current = false), 200)
  }, [])

  useEffect(() => {
    setMaxHeight(isExpanded ? contentRef.current.scrollHeight : 0)
  }, [isExpanded, contentRef])

  const handleClick = useCallback(event => {
    if (!event.target || lowerCase(event.target.tagName) !== 'a') {
      event.preventDefault()
    }

    if (isFocusedRef.current && !isClickLockedRef.current) {
      setIsExpanded(val => !val)
    }
  }, [])

  const handleFocus = useCallback(() => {
    lockdownClick()
    setIsExpanded(true)
    isFocusedRef.current = true
  }, [lockdownClick])

  const handleBlur = useCallback(() => {
    setIsExpanded(false)
    isFocusedRef.current = false
  }, [])

  return (
    <li
      className={classes(
        styles.accordionListItem,
        isExpanded && styles.expanded
      )}
      tabIndex={0}
      onClick={handleClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      id={slug}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <a href={`#${slug}`} className={styles.expandButton}>
          <PlusIcon className={classes(isExpanded && styles.rotate)} />
        </a>
      </div>
      <div className={styles.content} style={{ maxHeight }} ref={contentRef}>
        {children}
      </div>
    </li>
  )
}

export default AccordionListItem
