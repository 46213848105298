import PropTypes from 'prop-types'
import React from 'react'

import AccordionListItem from './accordion-list-item'
import styles from './accordion-list.module.css'
import { classes } from '../util/components'

const AccordionList = ({ className, children }) => {
  return (
    <ul className={classes(styles.accordionList, className)}>{children}</ul>
  )
}

AccordionList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(AccordionListItem),
}

export default AccordionList
