import React from 'react'

const PlusIcon = props => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" {...props}>
      <g stroke="#FFF" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M0 12.5h25M12.54 0l-.503 24.995"
          transform="translate(-1057 -412) translate(332 381) translate(725 31)"
        />
      </g>
    </svg>
  )
}

export default PlusIcon
